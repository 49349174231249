<a
  *ngIf="!isModal"
  [ngClass]="block ? 'block' : null"
  class="d-flex flex-column hover align-items-center text-center no-underline"
  target="_blank"
  style="cursor: pointer"
>
  <div class="icon-box-small mt-2 p-1 hover shadow-sm rounded-3">
    <img
      width="50"
      height="50"
      class="card-icon-small"
      [src]="imgPath"
      alt="Card Icon"
    />
  </div>
  <span
    class="h6 title mt-2 text-center d-inline-block text-wrap"
    style="width: 110px; height: 40px"
    >{{ title }}</span
  >
</a>

<a
  *ngIf="isModal"
  [ngClass]="block ? 'block' : null"
  class="d-flex card-hover modal hover flex-column align-items-center text-center no-underline"
  target="_blank"
>
  <div class="icon-box-small mt-1 p-1 border shadow-sm rounded">
    <img
      class="card-icon-small"
      width="50"
      height="50"
      [src]="imgPath"
      alt="Card Icon"
    />
  </div>
  <span
    class="h6 title mt-2 text-center d-inline-block text-wrap"
    style="width: 110px; height: 40px"
    >{{ title }}</span
  >
</a>
