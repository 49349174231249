import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsersApplicationsService {
  private userUpdatedSource = new Subject<void>();
  userUpdated$ = this.userUpdatedSource.asObservable();
  constructor(
    public httpClient: HttpClient,
    public securityService: OidcSecurityService
  ) {}
  index(properties?: any, length: number = 0, page: number = 0, max: number = 0, search = '') {
    const fromObject: any = {
      length: length.toString(),
      page: page.toString(),
      max: max.toString(),
    };
  
    if (properties) {
      Object.keys(properties).forEach((property: string) => {
        fromObject[property] = `${properties[property]}`;
      });
    }
  
    if (search !== '') {
      fromObject['search'] = search;
    }
  
    return this.httpClient.get(environment.url + 'users/member', {
      params: new HttpParams({ fromObject }),
      headers: {
        Authorization: 'Bearer ' + this.securityService.getToken(),
      },
    });
  }
  
  
  getUser() {
    this.validateSessionOrRefresh();
    return this.httpClient.get(`${environment.url}users/member/profile`, {
      headers: {
        Authorization: 'Bearer ' + this.securityService.getToken(),
      },
    });
  }
  deleteUser(userId: string): Observable<any> {
    return this.httpClient
      .delete(`${environment.url}users/member/${userId}`, {
        headers: {
          Authorization: 'Bearer ' + this.securityService.getToken(),
        },
      })
      .pipe(
        tap(() => {
          this.userUpdatedSource.next();
        })
      );
  }
  deleteStudy(id: string): Observable<any> {
    return this.httpClient.delete(
      `${environment.url}users/member/studies/delete/${id}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.securityService.getToken(),
        },
      }
    );
  }
  
  updateStudy(id: string, studyData: any): Observable<any> {
    return this.httpClient.put(
      `${environment.url}users/member/studies/edit/${id}`,
      studyData,
      {
        headers: {
          Authorization: 'Bearer ' + this.securityService.getToken(),
          'Content-Type': 'application/json',
        },
      }
    );
  }
  createUser(id: string, studyData: any): Observable<any> {
    return this.httpClient.put(
      `${environment.url}users/member/studies/edit/${id}`,
      studyData,
      {
        headers: {
          Authorization: 'Bearer ' + this.securityService.getToken(),
          'Content-Type': 'application/json',
        },
      }
    );
  }

  updateUser(userId: string, userData: any): Observable<any> {
    return this.httpClient.put(
      `${environment.url}users/member/${userId}`,
      userData,
      {
        headers: {
          Authorization: 'Bearer ' + this.securityService.getToken(),
          'Content-Type': 'application/json',
        },
      }
    );
  }
  createUserFromZero(userData: any): Observable<any> {
    return this.httpClient.post(
      `${environment.url}users/member`,
      userData,
      {
        headers: {
          Authorization: 'Bearer ' + this.securityService.getToken(),
          'Content-Type': 'application/json',
        },
      }
    );
  }
  
  getEspecificUser(id: string) {
    return this.httpClient.get(`${environment.url}users/member/${id}`, {
      headers: {
        Authorization: 'Bearer ' + this.securityService.getToken(),
      },
    });
  }

  getSoftwareStudies(software: string, userId: string) {
    this.validateSessionOrRefresh();
    return this.httpClient.get(
      `${environment.url}users/member/${userId}/studies/` + software,
      {
        headers: {
          Authorization: 'Bearer ' + this.securityService.getToken(),
        },
      }
    );
  }
  getAccessToken(id: string, application: string, studyId: string) {
    this.validateSessionOrRefresh();
    return this.httpClient.post(
      `${environment.url}users/member/access/${id}/${application}/${studyId}`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + this.securityService.getToken(),
        },
      }
    );
  }

  duplicateAndGetAccessToken(id: string, application: string, studyId: string) {
    this.validateSessionOrRefresh();
    return this.httpClient.post(
      `${environment.url}users/member/duplicate/${id}/${application}/${studyId}`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + this.securityService.getToken(),
        },
      }
    );
  }
  validateSessionOrRefresh() {
    this.securityService.isAuthenticated$.subscribe((isAuth) => {
      if (isAuth == false) {
        location.reload();
      }
    });
  }
}
