<app-softwares
  class="content-container"
  [haveLateralName]="true"
></app-softwares>
<hr />

<app-ecosystem
  class="content-container"
  [haveLateralName]="true"
></app-ecosystem>

<div *ngIf="!storeData.check">
  <hr />
  <app-education
    class="content-container"
    [haveLateralName]="true"
  ></app-education>

  <hr />
  <app-material class="content-container" [haveLateralName]="true">
  </app-material>
</div>
