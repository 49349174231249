import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { AuthService } from '../../shared/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalOpenApplicationComponent } from 'src/app/modal-open-application/modal-open-application.component';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  baseUrl: string = environment.baseUrl;
  accessToken: string;

  constructor(
    public authService: AuthService,
    public diolog: MatDialog,
    public ngxPermissionService: NgxPermissionsService,
  ) { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem('access_token');
  }

  openApplication(application: 'apolo' | 'selectus' | 'oraculo' | 'preciso') {
    const params = {
      application
    };

    const permissions = this.ngxPermissionService.getPermissions();
    if(permissions['members::list::my'] == undefined) {
      location.href = environment.clientRedirect.replace('techfinance', application).replace('api.qa', 'qa');
      return ;
    }
    this.diolog.open(ModalOpenApplicationComponent, {
      disableClose: true,
      data: params,
      panelClass: 'custom-dialog-container',
    });
  }

}
