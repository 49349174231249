import { createReducer, on } from '@ngrx/store';
import { IHeaderState } from './header.state';
import {
  initHeaderAction,
  setHeaderAction,
} from './header.actions';

export const initialHeaderState: IHeaderState = {
  check: false,
};

export const headerReduce = createReducer(
  initialHeaderState,
  on(initHeaderAction, (state) => ({
    ...state,
    check: false,
  })),
  on(setHeaderAction, (state, { check }) => ({
    ...state,
    check: check,
  }))
);
