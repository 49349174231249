<div id="carousel-techfinance" class="carousel slide mx-5 my-4" >
    <div class="carousel-inner pt-0 mt-0 pb-5 border">
      <!-- Slide 1 -->
      <div class="carousel-item active">
        <div class="row align-items-center custom-slide">
          <div class="col-6">
            <img src="../../../../../assets/images/carousel-techfinance.png" class="img-fluid custom-image" alt="Imagem 1" />
          </div>
          <div class="col-6 text-start px-4">
            <span class="badge p-2 bg-light border-dark border text-dark">TechFinance Business</span>
            <h2 class="mt-3 fw-medium">Consultoria Financeira Empresarial</h2>
            <p>Ajude pequenas e médias empresas e seja muito bem-remunerado(a) por isso. Conheça nossa solução Corporate e turbine seu volume de receitas.</p>
            <a target="_blank" style="background-color: #00658F;" href="https://pg.empreenderdinheiro.com.br/pv/techfinance-business/" class="btn text-white">Quero Conhecer o Modelo</a>
          </div>
        </div>
      </div>
  
      <!-- Slide 2 -->
      <div class="carousel-item">
        <div class="row align-items-center custom-slide">
          <div class="col-6">
            <img src="../../../../../assets/images/carousel-weath-planner.png" class="img-fluid custom-image" alt="Imagem 2" />
          </div>
          <div class="col-6 text-start px-4">
            <span style="background-color: #DAE2FF; font-size: 12px;" class="badge p-1 px-2 text-black">
              <svg
            width="25"
            class="mr-1 mb-1"
            height="25"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7348 4.55867L8.23484 0.558667C8.1625 0.520147 8.0818 0.5 7.99984 0.5C7.91789 0.5 7.83718 0.520147 7.76484 0.558667L0.264844 4.55867C0.184846 4.6013 0.117944 4.66488 0.0712937 4.7426C0.0246433 4.82033 0 4.90927 0 4.99992C0 5.09057 0.0246433 5.17951 0.0712937 5.25723C0.117944 5.33495 0.184846 5.39853 0.264844 5.44117L1.99984 6.36679V9.39304C1.99932 9.63864 2.08971 9.87574 2.25359 10.0587C3.07234 10.9705 4.90672 12.4999 7.99984 12.4999C9.02544 12.5084 10.0434 12.3226 10.9998 11.9524V13.9999C10.9998 14.1325 11.0525 14.2597 11.1463 14.3535C11.2401 14.4472 11.3672 14.4999 11.4998 14.4999C11.6325 14.4999 11.7596 14.4472 11.8534 14.3535C11.9472 14.2597 11.9998 14.1325 11.9998 13.9999V11.4693C12.6518 11.0929 13.241 10.617 13.7461 10.0587C13.91 9.87574 14.0004 9.63864 13.9998 9.39304V6.36679L15.7348 5.44117C15.8148 5.39853 15.8817 5.33495 15.9284 5.25723C15.975 5.17951 15.9997 5.09057 15.9997 4.99992C15.9997 4.90927 15.975 4.82033 15.9284 4.7426C15.8817 4.66488 15.8148 4.6013 15.7348 4.55867ZM7.99984 11.4999C5.29547 11.4999 3.70484 10.1787 2.99984 9.39304V6.89992L7.76484 9.44117C7.83718 9.47969 7.91789 9.49983 7.99984 9.49983C8.0818 9.49983 8.1625 9.47969 8.23484 9.44117L10.9998 7.96679V10.863C10.2123 11.2305 9.21984 11.4999 7.99984 11.4999ZM12.9998 9.39054C12.7001 9.72313 12.3648 10.0218 11.9998 10.2812V7.43304L12.9998 6.89992V9.39054ZM11.7498 6.43367L11.7361 6.42554L8.23609 4.55867C8.11932 4.49902 7.98379 4.48764 7.8587 4.52696C7.73361 4.56629 7.62898 4.65318 7.56734 4.76891C7.50571 4.88464 7.492 5.01996 7.52917 5.1457C7.56635 5.27145 7.65143 5.37755 7.76609 5.44117L10.6873 6.99992L7.99984 8.43304L1.56234 4.99992L7.99984 1.56679L14.4373 4.99992L11.7498 6.43367Z"
              fill="#191C1E"
            /></svg>
              Educação</span>
            <h2 class="mt-3 fw-medium">Inicie sua Formação Wealth Planner</h2>
            <p>Torne-se o profissional de finanças pessoais mais completo do mercado financeiro.</p>
            <a style="background-color: #00658F;" href="https://pg.empreenderdinheiro.com.br/wealth-planner/" target="_blank" class="btn text-white">Acessar Formação
            
              <svg width="21" height="18" viewBox="0 0 18 16" fill="none" class="ml-1" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.7806 8.53055L11.0306 15.2806C10.8899 15.4213 10.699 15.5003 10.5 15.5003C10.301 15.5003 10.1101 15.4213 9.96937 15.2806C9.82864 15.1398 9.74958 14.949 9.74958 14.7499C9.74958 14.5509 9.82864 14.36 9.96937 14.2193L15.4397 8.74993H0.75C0.551088 8.74993 0.360322 8.67091 0.21967 8.53026C0.0790178 8.38961 0 8.19884 0 7.99993C0 7.80102 0.0790178 7.61025 0.21967 7.4696C0.360322 7.32895 0.551088 7.24993 0.75 7.24993H15.4397L9.96937 1.78055C9.82864 1.63982 9.74958 1.44895 9.74958 1.24993C9.74958 1.05091 9.82864 0.860034 9.96937 0.719304C10.1101 0.578573 10.301 0.499512 10.5 0.499512C10.699 0.499512 10.8899 0.578573 11.0306 0.719304L17.7806 7.4693C17.8504 7.53896 17.9057 7.62168 17.9434 7.71272C17.9812 7.80377 18.0006 7.90137 18.0006 7.99993C18.0006 8.09849 17.9812 8.19609 17.9434 8.28713C17.9057 8.37818 17.8504 8.4609 17.7806 8.53055Z" fill="white"/>
                </svg></a>
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-indicators custom-indicators">
      <button type="button" data-bs-target="#carousel-techfinance" data-bs-slide-to="0" class="active" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carousel-techfinance" data-bs-slide-to="1" aria-label="Slide 2"></button>
    </div>
</div>

