import { Component, OnInit, ElementRef, ViewChild, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { getHeaderSelectorsState } from './components/header/store/header.selectors';
import { LoadingService } from 'src/app/shared/loading.service';
import { UsersApplicationsService } from 'src/app/modal-open-application/users-applications.service';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit{

  check$: Observable<any>;
  storeData: any
  userName: String = '';
  currentPage: string = 'softwares';
  currentUser: any;
  showDisplayAlertNotification = false;
  showDisplayBannerNotification = false
  @ViewChild('footer') footer!: ElementRef; 

  constructor(private loadingService:LoadingService,private userService: UsersApplicationsService,private oidcSecurityService: OidcSecurityService, private renderer: Renderer2, private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getuserName();

    this.loadingService.setLoadingState('home');
    this.setDataProfile();
    this.initializeNotificationDisplay();
    this.getStates();
  }
  getuserName(){
    this.userService.getUser().subscribe((usersList: any) => {
      this.userName = usersList.firstName;
    });
  }
  getStates() {
    this.check$ = this.store.select(getHeaderSelectorsState);
    this.check$.subscribe((value) => {
      this.storeData = {
        ...(this.storeData = value)
      }
      if (value.check) this.update('home')

      this.cdr.detectChanges();
    });
  }

  private setDataProfile(): void {
    this.oidcSecurityService.userData$.subscribe((profileData) => {
      this.currentUser = profileData;
    });
  }

  update(event: string): void {
    this.currentPage = event;

  }

  openDisplayNotification(notification: string): void {
    if (notification == 'alert') {
      localStorage.setItem('show_display_alert_notification', 'true');
      this.showDisplayAlertNotification = true;  
    } else {
      localStorage.setItem('show_display_banner_notification', 'true');
      this.showDisplayBannerNotification = true;
    }
  }

  closeDisplayNotification(notification: string): void {
    if (notification == 'banner') {
      localStorage.setItem('show_display_banner_notification', 'false');
      this.showDisplayBannerNotification = false;  
    } else {
      localStorage.setItem('show_display_alert_notification', 'false');
      this.showDisplayAlertNotification = false;
    }
  }

  private initializeNotificationDisplay(): void {
    const showDisplayAlertNotification = localStorage.getItem('show_display_alert_notification');
    const showDisplayBannerNotificationNotification = localStorage.getItem('show_display_banner_notification');

    if (showDisplayAlertNotification == null || showDisplayAlertNotification == 'true') {
      this.openDisplayNotification('alert');
    } 
    else {
      this.closeDisplayNotification('alert')
    }

    if (showDisplayBannerNotificationNotification == null || showDisplayBannerNotificationNotification == 'true') {
      this.openDisplayNotification('banner');
    } 
    else {
      this.closeDisplayNotification('banner')
    }

  }

}