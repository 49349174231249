import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getHeaderSelectorsState } from '../header/store/header.selectors';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  currentTopic: string = 'home';
  check$: Observable<any>;
  storeData: any
  
  @Output() page = new EventEmitter<string>();
  updatePage() {

    this.page.emit(this.currentTopic);
  }

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.updatePage()
    this.getStates()
  }

  getStates() {
    this.check$ = this.store.select(getHeaderSelectorsState);
    this.check$.subscribe((value) => {
      this.storeData = {
        ...(this.storeData = value)
      }

      if (value.check) this.currentTopic = 'home'
    
    });
  }

}
