import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
  }

  currentSlide = 0;

  ngAfterViewInit() {
    const carouselElement = document.getElementById('carousel-techfinance');
    if (carouselElement) {
     
      const carousel = new bootstrap.Carousel(carouselElement, {
        interval: 4000, 
        ride: 'carousel'
      });

      carouselElement.addEventListener('slid.bs.carousel', (event: any) => {
        this.currentSlide = event.to;
      });
    }
  }

  goToSlide(slideIndex: number) {
    this.currentSlide = slideIndex;
    const carouselElement = document.getElementById('carousel-techfinance');
    if (carouselElement) {
      const carousel = bootstrap.Carousel.getInstance(carouselElement);
      carousel.to(slideIndex);
    }
  }
}
