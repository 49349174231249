import { Component } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NgxPermissionsService } from 'ngx-permissions';
import { filter, switchMap } from 'rxjs/operators';
import { UserService } from './shared/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dashboard';
  constructor( private oidcSecurityService: OidcSecurityService, private userService: UserService, private ngxPermissionsService: NgxPermissionsService, ) {
  }
  ngOnInit() {
    this.oidcSecurityService.userData$
      .pipe(
        filter((user) => {
          return user != null;
        }),
        switchMap(() => this.userService.profile())
      )
      .subscribe((userData: any) => {
      if(userData === null) return;
      let permissions = [
        'loaded',
      ];
      if(userData.permissions.groups != undefined ) {
        let rules = [];
        if(userData.permissions.rules !== undefined) {
          rules = userData.permissions.rules;
        } 
        permissions = [
          'loaded',
          ...userData.permissions.groups.reduce((prev: any, curr: any) => [...prev, ...curr.rules], []),
          ...rules,
        ];
      }
      this.ngxPermissionsService.loadPermissions(permissions);
    });
    const nativeLoading = document.getElementById("loadingAngular");
    nativeLoading?.parentNode?.removeChild(nativeLoading);
  }
}
