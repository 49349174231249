import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  Renderer2,
  AfterViewInit,
  HostListener,
  AfterContentInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersApplicationsService } from 'src/app/modal-open-application/users-applications.service';
import { map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { ClientResponse } from 'src/app/interfaces/client-response';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { LoadingService } from 'src/app/shared/loading.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationModalComponent } from '../modals/configuration-modal/configuration-modal.component';
import { ModalOpenApplicationComponent } from 'src/app/modal-open-application/modal-open-application.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-a-client',
  templateUrl: './select-a-client.component.html',
  styleUrls: ['./select-a-client.component.scss'],
})
export class SelectAClientComponent implements OnInit, AfterContentInit {
  ispersonalstudies: string | null = null;
  search = new FormControl('');
  @ViewChild('searchElement') searchElement: ElementRef;
  @ViewChild('footer') footer!: ElementRef;
  @ViewChild('apolocomponent') apolocomponent: ModalOpenApplicationComponent;

  usersList: ClientResponse[] = [];
  filteredUsersList: ClientResponse[] = [];
  adminId: string;
  application: string;
  clientId: string | undefined;
  loading = false;
  pageSize = 5;
  currentPage = 1;
  numberOfPages: number = 1;
  numberOfItems: number;

  constructor(
    public usersApplicationsService: UsersApplicationsService,
    private toastr: ToastrService,
    private router: Router,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngAfterContentInit(): void {}

  ngOnInit(): void {
    this.application = this.loadingService.getLoadingState();

    this.usersApplicationsService.getUser().subscribe((usersList: any) => {
      this.adminId = usersList._id;
    });

    this.route.paramMap.subscribe((params) => {
      this.ispersonalstudies = params.get('personalstudies');
    });

    this.search.valueChanges
    .pipe(  
      startWith(''),
      tap((search: string) => {
        this.currentPage = 1;


      }),
      switchMap((search: string) => {
        if (search !== '') {
          return this.usersApplicationsService.index(
            {},
            0,
            0,
            100,
            search
          ).pipe(
            tap((response: any) => {
              this.numberOfItems = response.results.length;
              this.numberOfPages = Math.ceil(this.numberOfItems / this.pageSize);
            }),
            switchMap(() => 
              this.usersApplicationsService.index(
                {},
                this.numberOfItems,
                this.currentPage - 1,
                this.pageSize,
                search
              )
            )
          );
        }
        return this.usersApplicationsService.index(
          {},
          this.numberOfItems,
          this.currentPage - 1,
          this.pageSize,
          search
        );

      }),
      tap((response: any) => {
        if (this.search.value) {

        } else {
          this.numberOfItems = response.count;
          this.numberOfPages = Math.ceil(this.numberOfItems / this.pageSize);
        }
        this.cdr.detectChanges();
      }),
      map((response: any) => response.results)
    )
    .subscribe((usersList: ClientResponse[]) => {
      this.usersList = usersList;
      this.filteredUsersList = this.usersList.slice(0, this.pageSize);
      this.loading = true;
      this.cdr.detectChanges();
    });
  
  setTimeout(() => {
    this.searchElement.nativeElement.focus();
  }, 0);
  
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {}

  goToPage(page: number): void {
    if (page < 1 || page > this.numberOfPages) {
      return; 
    }
  
    this.currentPage = page;
    this.fetchUserList();
  }
  

  fetchUserList(): void {
    const searchValue = this.search.value || '';
    this.usersApplicationsService
      .index(
        {},
        this.numberOfItems,
        this.currentPage - 1,
        this.pageSize,
        searchValue
      )
      .pipe(
        tap((response: any) => {
          this.numberOfItems = response.count;
          this.cdr.detectChanges();
        }),
        map((response: any) => response.results)
      )
      .subscribe((usersList: ClientResponse[]) => {
        this.usersList = usersList;
        this.filteredUsersList = this.usersList;
        this.loading = true;

        this.cdr.detectChanges();
      });
    this.cdr.detectChanges();
  }

  updateUserList(): void {
    this.search.setValue(this.search.value);
    this.currentPage = 1
  }

  getPages(): number[] {
    const totalButtons = 5;
    const pages: number[] = [];
  
    if (this.numberOfPages <= totalButtons) {
      return Array.from({ length: this.numberOfPages }, (_, i) => i + 1);
    }
  
    pages.push(1);
  
    const start = Math.max(2, this.currentPage - 1);
    const end = Math.min(this.numberOfPages - 1, this.currentPage + 1);
  

    if (start > 2) {
      pages.push(-1); 
    }
  
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }
  
    if (end < this.numberOfPages - 1) {
      pages.push(-1); 
    }
  
    pages.push(this.numberOfPages);
  
    return pages;
  }
  
  openLink(id: string) {
    this.clientId = id;
  }

  openLinkcadastro(subdomain: string, route: string) {
    if (subdomain === 'login' && environment.isQa === true) {
      location.href = (
        'https://' +
        subdomain +
        '.' +
        environment.baseUrl +
        route
      ).replace('login.tf', 'login');
      return;
    }
    location.href = (
      environment.clientRedirect.replace('techfinance', subdomain) + route
    )
      .replace('.qa.', '.qa.')
      .replace('login.tf', 'login');
  }

  get startIndex(): number {
    return (this.currentPage - 1) * this.pageSize;
  }

  get endIndex(): number {
    return this.currentPage * this.pageSize;
  }

  get totalPages(): number {
    return Math.ceil(this.usersList.length / this.pageSize);
  }

  redirectHome() {
    this.router.navigate(['/']);
  }

  redirectBack() {
    this.clientId = undefined;
  }

  openApplicationModal(id: string) {
    const dialogRef = this.dialog.open(ConfigurationModalComponent, {
      disableClose: true,
      data: {
        UserId: id,
      },
      panelClass: 'custom-dialog-container',
    });

    dialogRef
    .afterClosed()
    .pipe(take(1))
    .subscribe((result) => {
      if (result) {
        if (result.updated) {
          this.toastr.success('Suas edições foram salvas');
        }
        if (result.deleted) {
          this.usersApplicationsService.index(
            {},
            0,
            0,
            100,
            this.search.value 
          ).pipe(
            tap((response: any) => {
              if (this.search.value) { 
                this.numberOfItems = response.results.length;
                this.numberOfPages = Math.ceil(this.numberOfItems / this.pageSize);
              } else {
                this.numberOfItems = response.count;
                this.numberOfPages = Math.ceil(this.numberOfItems / this.pageSize);
              }
            })
          ).subscribe(); 
          
          
          this.toastr.success(`Cliente "${result.name}" foi excluído`);
        }

        this.currentPage = result.updated ? this.currentPage : 1;

        this.fetchUserList();
      }
    });
  }
}
