import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersApplicationsService } from '../../../modal-open-application/users-applications.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-configuration-modal',
  templateUrl: './configuration-modal.component.html',
  styleUrls: ['./configuration-modal.component.scss'],
})
export class ConfigurationModalComponent implements OnInit, AfterViewInit {
  userResponse: any = {};
  name: string = '';
  userForm: FormGroup;
  loading: boolean = false;
  errorMessage: String = '';
  deleteConfirmation: boolean = false;
  closeConfirmation: boolean = false;
  constructor(
    private fb: FormBuilder,
    public usersApplicationsService: UsersApplicationsService,
    public dialog: MatDialog,
    public route: Router,
    public dialogRef: MatDialogRef<ConfigurationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { UserId: string }
  ) {}

  ngOnInit(): void {
    this.loading = false;
    this.userForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
    });

    this.usersApplicationsService
      .getEspecificUser(this.data.UserId)
      .subscribe((userlist: any) => {
        this.userResponse = userlist;
        if (this.userResponse.phone) {
          this.userResponse.phone = this.formatPhoneNumber(userlist.phone);
        } else {
          this.userResponse.phone = ''
        }
        this.name = `${userlist.firstName || ''} ${
          userlist.lastName || ''
        }`.trim();
        this.userForm.patchValue({
          name: this.name,
          email: userlist.email,
          phone: this.userResponse.phone,
        });
      });
  }
  ngAfterViewInit(): void {
    this.loading = true;
  }
  closeScreen() {
    this.dialogRef.close();
  }
  formatPhoneNumber(phone: string): string {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }

  editNumberType(event: any): void {
    let input = event.target.value.replace(/\D/g, '');

    if (input.length > 11) {
      input = input.substring(0, 11);
    }

    if (input.length <= 10) {
      input = input.replace(/(\d{2})(\d{4})(\d{0,4})/, '($1) $2-$3');
    } else {
      input = input.replace(/(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3');
    }

    this.userForm.controls['phone'].setValue(input, { emitEvent: false });
  }

  onSubmit() {
    if (this.userForm.valid) {
      const fullName = this.userForm.get('name')?.value.trim().split(' ');
      const firstName = fullName[0];
      const lastName = fullName.length > 1 ? fullName.slice(1).join(' ') : '';

      const updatedUserData = {
        email: this.userForm.get('email')?.value,
        firstName: firstName,
        lastName: lastName,
        phone: this.userForm.get('phone')?.value.replace(/[^\d]/g, ''),
        origin: this.userResponse.origin,
        password: this.userResponse.password,
        permissions: this.userResponse.permissions,
        selectusActive: this.userResponse.selectusActive,
        updatedAt: new Date().toISOString(),
      };

      this.usersApplicationsService
        .updateUser(this.data.UserId, updatedUserData)
        .subscribe(
          (response) => {
            this.dialogRef.close({ updated: true });
          },
          (error) => {
            this.errorMessage = error.error.message;
          }
        );
    }
  }
  deleteScreenCall(screenCall: boolean) {
    this.deleteConfirmation = screenCall;
  }
  deleteUser(): void {
    const fullName = this.userForm
      .get('name')
      ?.value.trim()
      .split(' ')
      .join(' ');

    this.usersApplicationsService.deleteUser(this.data.UserId).subscribe(() => {
      this.dialogRef.close({ deleted: true, name: fullName });
      this.route.navigate(['/estudos/usuarios']);
    });
  }
  closeModal(): void {
    this.dialogRef.close();
  }
}
