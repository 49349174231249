import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private LOADING_KEY = 'loading_state';

  constructor() {}

  setLoadingState(loadingState: string): void {
    // Verifica se termina com .tf e remove caso necessário
    if (loadingState.endsWith('.tf')) {
      loadingState = loadingState.slice(0, -3);
    }

    sessionStorage.setItem(this.LOADING_KEY, loadingState);
  }

  getLoadingState(): string {
    return sessionStorage.getItem(this.LOADING_KEY) || '';
  }

  clearLoadingState(): void {
    sessionStorage.removeItem(this.LOADING_KEY);
  }
}
