<div *ngIf="application !== 'dashplan.tf'">
  <div
    class="card-modal mb-3 select-modal"
    *ngIf="simpleRouteEnum == 'home' && application != 'dashplan'"
  >
    <div class="w-100 mt-3">
      <div class="row">
        <div class="col-10">
          <div
            *ngIf="application === 'apolo.tf'"
            class="col-12 title-col d-flex align-items-center mb-0"
          >
            <img
              src="/assets/images/homeTF/logo-apolo.svg"
              width="30px"
              style="margin-right: 14px; padding-bottom: 14px"
            />
            <h4 style="font-weight: 600" #foco>Apolo</h4>
          </div>

          <div
            *ngIf="application === 'oraculo.tf'"
            class="col-12 title-col d-flex align-items-center mb-0"
          >
            <img
              src="/assets/images/homeTF/logo-oraculo.svg"
              width="30px"
              style="margin-right: 14px; padding-bottom: 14px"
            />
            <h4 style="font-weight: 600" #foco>Oráculo</h4>
          </div>
        </div>

        <svg
          width="18"
          height="18"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mb-2 col-1 ml-4 close-button"
          (click)="modalClose()"
        >
          <path
            d="M15.2807 14.2193C15.3504 14.289 15.4056 14.3717 15.4433 14.4628C15.4811 14.5538 15.5005 14.6514 15.5005 14.7499C15.5005 14.8485 15.4811 14.9461 15.4433 15.0371C15.4056 15.1281 15.3504 15.2109 15.2807 15.2806C15.211 15.3502 15.1283 15.4055 15.0372 15.4432C14.9462 15.4809 14.8486 15.5003 14.7501 15.5003C14.6515 15.5003 14.5539 15.4809 14.4629 15.4432C14.3718 15.4055 14.2891 15.3502 14.2194 15.2806L8.00005 9.06024L1.78068 15.2806C1.63995 15.4213 1.44907 15.5003 1.25005 15.5003C1.05103 15.5003 0.860156 15.4213 0.719426 15.2806C0.578695 15.1398 0.499634 14.949 0.499634 14.7499C0.499634 14.5509 0.578695 14.36 0.719426 14.2193L6.93974 7.99993L0.719426 1.78055C0.578695 1.63982 0.499634 1.44895 0.499634 1.24993C0.499634 1.05091 0.578695 0.860034 0.719426 0.719304C0.860156 0.578573 1.05103 0.499512 1.25005 0.499512C1.44907 0.499512 1.63995 0.578573 1.78068 0.719304L8.00005 6.93962L14.2194 0.719304C14.3602 0.578573 14.551 0.499512 14.7501 0.499512C14.9491 0.499512 15.1399 0.578573 15.2807 0.719304C15.4214 0.860034 15.5005 1.05091 15.5005 1.24993C15.5005 1.44895 15.4214 1.63982 15.2807 1.78055L9.06036 7.99993L15.2807 14.2193Z"
            fill="#191C1E"
          />
        </svg>
      </div>

      <div class="subtitle mt-0">Selecione o que deseja acessar:</div>

      <div
        class="mat-list-content mt-4 d-flex justify-content-center align-items-center"
      >
        <div class="list-group col-11 ml-3 mt-2">
          <button
            (click)="routeChanger('select-a-client')"
            class="list-group-item list-group-item-action modal-buttons-dashplan rounded mb-2"
          >
            <div class="d-flex align-items-center">
              <img
                src="/assets/images/two-persons-icon.png"
                width="25px"
                style="margin-right: 14px"
              />
              <i class="fas fa-check me-3"></i>
              <span class="list-group-text"
                ><p class="m-0">Acessar Estudo de Cliente</p></span
              >
            </div>
          </button>

          <button
            routerLink="/estudos/usuarios/create"
            class="list-group-item list-group-item-action border rounded mb-2 mt-2 modal-buttons-dashplan"
          >
            <div class="d-flex align-items-center">
              <img
                src="/assets/images/new-person-icon.png"
                width="25px"
                style="margin-right: 14px"
              />
              <i class="fas fa-user-plus me-3"></i>
              <span class="list-group-text"
                ><p class="m-0">Cadastrar Novo Cliente</p></span
              >
            </div>
          </button>

          <hr />

          <button
            (click)="routeChanger('personal-study')"
            class="list-group-item list-group-item-action border rounded mt-2 modal-buttons"
          >
            <div class="d-flex align-items-center">
              <img
                src="/assets/images/paper-icon.png"
                width="15px"
                style="margin-right: 24px"
              />
              <i class="fas fa-folder-open me-3"></i>
              <span class="list-group-text">Estudo Pessoal</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="application === 'dashplan'">
  <div
    [ngClass]="
      isDashplanVideo == true ? 'select-modal-dashplan' : 'select-modal-dashplan-home'
    "
    class="card-modal mb-3"
    *ngIf="simpleRouteEnum == 'home'"
  >
    <div class="w-100 mt-3">
      <div class="row">
        <div class="col-10">
          <div class="col-12 title-col d-flex align-items-center mb-0">
            <img
              src="/assets/images/homeTF/logo-dashplan.svg"
              width="30px"
              style="margin-right: 14px; padding-bottom: 14px"
              *ngIf="isDashplanVideo == false"
            />
            <svg
            width="21"
            height="20"
            viewBox="0 0 18 16"
            class="mr-3 mb-3"
            style="cursor: pointer;"
            (click)="isDashplanVideo=false"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            *ngIf="isDashplanVideo == true"
          >
            <path
              d="M17.9996 8.00005C17.9996 8.19896 17.9206 8.38973 17.7799 8.53038C17.6393 8.67103 17.4485 8.75005 17.2496 8.75005H2.55993L8.03024 14.2194C8.09992 14.2891 8.1552 14.3718 8.19291 14.4629C8.23062 14.5539 8.25003 14.6515 8.25003 14.7501C8.25003 14.8486 8.23062 14.9462 8.19291 15.0372C8.1552 15.1283 8.09992 15.211 8.03024 15.2807C7.96056 15.3504 7.87783 15.4056 7.78679 15.4433C7.69574 15.4811 7.59816 15.5005 7.49961 15.5005C7.40107 15.5005 7.30349 15.4811 7.21244 15.4433C7.1214 15.4056 7.03867 15.3504 6.96899 15.2807L0.218988 8.53068C0.149256 8.46102 0.0939369 8.3783 0.0561936 8.28726C0.0184504 8.19621 -0.000976562 8.09861 -0.000976562 8.00005C-0.000976562 7.90149 0.0184504 7.80389 0.0561936 7.71285C0.0939369 7.6218 0.149256 7.53908 0.218988 7.46943L6.96899 0.719426C7.10972 0.578695 7.30059 0.499634 7.49961 0.499634C7.69864 0.499634 7.88951 0.578695 8.03024 0.719426C8.17097 0.860156 8.25003 1.05103 8.25003 1.25005C8.25003 1.44907 8.17097 1.63995 8.03024 1.78068L2.55993 7.25005H17.2496C17.4485 7.25005 17.6393 7.32907 17.7799 7.46972C17.9206 7.61037 17.9996 7.80114 17.9996 8.00005Z"
              fill="#191C1E"
            />
          </svg>
            <h4 style="font-weight: 600" *ngIf="isDashplanVideo == false"#foco>Dashplan</h4>
            <h4 style="font-weight: 500; font-size: larger;" *ngIf="isDashplanVideo == true"#foco>Como Usar a Dashplan</h4>

          </div>
        </div>

        <svg
          width="18"
          height="18"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mb-3 col-1 ml-4 close-button"
          [ngClass]="{ 'ms-5 ps-1 close-button-video': isDashplanVideo }"
          (click)="modalClose()"
        >
          <path
            d="M15.2807 14.2193C15.3504 14.289 15.4056 14.3717 15.4433 14.4628C15.4811 14.5538 15.5005 14.6514 15.5005 14.7499C15.5005 14.8485 15.4811 14.9461 15.4433 15.0371C15.4056 15.1281 15.3504 15.2109 15.2807 15.2806C15.211 15.3502 15.1283 15.4055 15.0372 15.4432C14.9462 15.4809 14.8486 15.5003 14.7501 15.5003C14.6515 15.5003 14.5539 15.4809 14.4629 15.4432C14.3718 15.4055 14.2891 15.3502 14.2194 15.2806L8.00005 9.06024L1.78068 15.2806C1.63995 15.4213 1.44907 15.5003 1.25005 15.5003C1.05103 15.5003 0.860156 15.4213 0.719426 15.2806C0.578695 15.1398 0.499634 14.949 0.499634 14.7499C0.499634 14.5509 0.578695 14.36 0.719426 14.2193L6.93974 7.99993L0.719426 1.78055C0.578695 1.63982 0.499634 1.44895 0.499634 1.24993C0.499634 1.05091 0.578695 0.860034 0.719426 0.719304C0.860156 0.578573 1.05103 0.499512 1.25005 0.499512C1.44907 0.499512 1.63995 0.578573 1.78068 0.719304L8.00005 6.93962L14.2194 0.719304C14.3602 0.578573 14.551 0.499512 14.7501 0.499512C14.9491 0.499512 15.1399 0.578573 15.2807 0.719304C15.4214 0.860034 15.5005 1.05091 15.5005 1.24993C15.5005 1.44895 15.4214 1.63982 15.2807 1.78055L9.06036 7.99993L15.2807 14.2193Z"
            fill="#191C1E"
          />
        </svg>
      </div>

      <div *ngIf="isDashplanVideo == false" class="subtitle mt-0 ml-5">
        Selecione uma forma de acesso:
      </div>

      <div
        class="mat-list-content mt-2 d-flex justify-content-center align-items-center"
      >
        <div
          class="list-group col-11 ml-3 mt-2"
          style="cursor: pointer"
          *ngIf="isDashplanVideo == false"
        >
          <a
            (click)="isDashplanVideo = true"
            class="list-group-item list-group-item-action border rounded mb-3 mt-2 modal-buttons-dashplan"
            tabindex="-1"
          >
            <div class="d-flex align-items-top mb-5">
              <img
              class="mb-3"
                src="/assets/images/play-circle.svg"
                width="24px"
                style="margin-right: 14px"
              />
              <div>
                <span class="list-group-text"
                  ><p class="m-0">Como Usar a Dashplan</p></span
                >
                <p class="small font-small text-muted">
                  Entenda tudo sobre a Dashplan
                </p>
              </div>
            </div>
          </a>
          <hr class="col-11 mt-0 mb-2" />
          <a
            href="https://app.dashplan.com.br/login"
            class="list-group-item list-group-item-action border rounded mb-3 mt-2 modal-buttons-dashplan"
            tabindex="-1"
          >
            <div class="d-flex align-items-center">
              <svg
                style="margin-right: 14px"
                width="22"
                height="22"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.2806 9.53063L8.53063 13.2806C8.46094 13.3503 8.37822 13.4056 8.28717 13.4433C8.19613 13.481 8.09855 13.5004 8 13.5004C7.90145 13.5004 7.80387 13.481 7.71283 13.4433C7.62178 13.4056 7.53906 13.3503 7.46937 13.2806C7.39969 13.2109 7.34442 13.1282 7.3067 13.0372C7.26899 12.9461 7.24958 12.8485 7.24958 12.75C7.24958 12.6515 7.26899 12.5539 7.3067 12.4628C7.34442 12.3718 7.39969 12.2891 7.46937 12.2194L9.93969 9.75H1.25C1.05109 9.75 0.860322 9.67098 0.71967 9.53033C0.579018 9.38968 0.5 9.19891 0.5 9C0.5 8.80109 0.579018 8.61032 0.71967 8.46967C0.860322 8.32902 1.05109 8.25 1.25 8.25H9.93969L7.46937 5.78063C7.32864 5.63989 7.24958 5.44902 7.24958 5.25C7.24958 5.05098 7.32864 4.86011 7.46937 4.71937C7.61011 4.57864 7.80098 4.49958 8 4.49958C8.19902 4.49958 8.38989 4.57864 8.53063 4.71937L12.2806 8.46937C12.3504 8.53903 12.4057 8.62175 12.4434 8.7128C12.4812 8.80384 12.5006 8.90144 12.5006 9C12.5006 9.09856 12.4812 9.19616 12.4434 9.2872C12.4057 9.37825 12.3504 9.46097 12.2806 9.53063ZM17 0H11.75C11.5511 0 11.3603 0.0790178 11.2197 0.21967C11.079 0.360322 11 0.551088 11 0.75C11 0.948912 11.079 1.13968 11.2197 1.28033C11.3603 1.42098 11.5511 1.5 11.75 1.5H17V16.5H11.75C11.5511 16.5 11.3603 16.579 11.2197 16.7197C11.079 16.8603 11 17.0511 11 17.25C11 17.4489 11.079 17.6397 11.2197 17.7803C11.3603 17.921 11.5511 18 11.75 18H17C17.3978 18 17.7794 17.842 18.0607 17.5607C18.342 17.2794 18.5 16.8978 18.5 16.5V1.5C18.5 1.10218 18.342 0.720644 18.0607 0.43934C17.7794 0.158035 17.3978 0 17 0Z"
                  fill="#00658F"
                />
              </svg>
              <div>
                <span class="list-group-text"
                  ><p class="m-0">Login Dashplan</p></span
                >
                <p class="mb-1 small font-small text-muted">
                  conta já existente
                </p>
              </div>
            </div>
          </a>

          <a
            href="https://empreenderdinheiro.typeform.com/to/ds5vd0IN?typeform-source=empreenderdinheiro.atlassian.net"
            class="list-group-item list-group-item-action border rounded mb-3 mt-2 modal-buttons-dashplan"
            tabindex="-1"
          >
            <div class="d-flex align-items-center">
              <img
                src="/assets/images/new-person-icon.png"
                width="23px"
                style="margin-right: 14px"
              />
              <div>
                <span class="list-group-text"
                  ><p class="m-0">Solicitar Primeiro Acesso</p></span
                >
                <p class="mb-1 small font-small text-muted">Nova conta</p>
              </div>
            </div>
          </a>
        </div>
        <div
          class="list-group col-11 ml-3 mt-1"
          *ngIf="isDashplanVideo == true"
        >
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/1024866182?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            width="765"
            height="480"
            frameborder="0"
            allowfullscreen
          ></iframe>
          <p class="mt-1">Acesse os 12 passos para cadastrar seu cliente e iniciar na Dashplan.</p>
          <a
          target="_blank"
          href="https://pg.empreenderdinheiro.com.br/wp-content/uploads/2024/10/12-Passos-para-Iniciar-no-Dashplan.pdf"
            class="btn border border-black text-primary col-5"
          >
          Baixar os 12 Passos Dashplan
          <svg class="ms-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 9.75C21 9.94891 20.921 10.1397 20.7803 10.2803C20.6397 10.421 20.4489 10.5 20.25 10.5C20.0511 10.5 19.8603 10.421 19.7197 10.2803C19.579 10.1397 19.5 9.94891 19.5 9.75V5.56125L13.2816 11.7806C13.1408 11.9214 12.95 12.0004 12.7509 12.0004C12.5519 12.0004 12.361 11.9214 12.2203 11.7806C12.0796 11.6399 12.0005 11.449 12.0005 11.25C12.0005 11.051 12.0796 10.8601 12.2203 10.7194L18.4387 4.5H14.25C14.0511 4.5 13.8603 4.42098 13.7197 4.28033C13.579 4.13968 13.5 3.94891 13.5 3.75C13.5 3.55109 13.579 3.36032 13.7197 3.21967C13.8603 3.07902 14.0511 3 14.25 3H20.25C20.4489 3 20.6397 3.07902 20.7803 3.21967C20.921 3.36032 21 3.55109 21 3.75V9.75ZM17.25 12C17.0511 12 16.8603 12.079 16.7197 12.2197C16.579 12.3603 16.5 12.5511 16.5 12.75V19.5H4.5V7.5H11.25C11.4489 7.5 11.6397 7.42098 11.7803 7.28033C11.921 7.13968 12 6.94891 12 6.75C12 6.55109 11.921 6.36032 11.7803 6.21967C11.6397 6.07902 11.4489 6 11.25 6H4.5C4.10218 6 3.72064 6.15804 3.43934 6.43934C3.15804 6.72064 3 7.10218 3 7.5V19.5C3 19.8978 3.15804 20.2794 3.43934 20.5607C3.72064 20.842 4.10218 21 4.5 21H16.5C16.8978 21 17.2794 20.842 17.5607 20.5607C17.842 20.2794 18 19.8978 18 19.5V12.75C18 12.5511 17.921 12.3603 17.7803 12.2197C17.6397 12.079 17.4489 12 17.25 12Z" fill="#00658F"/>
            </svg>
            
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-select-client-study
  [application]="application"
  [clientId]="myId"
  *ngIf="simpleRouteEnum == 'my-studies' && myId !== undefined"
  [isPersonStudies]="true"
></app-select-client-study>
<app-select-client
  [application]="application"
  *ngIf="simpleRouteEnum == 'select-client'"
></app-select-client>
