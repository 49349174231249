import { createAction, props } from '@ngrx/store';

export const initHeaderAction = createAction(
  '[Header Page] Home page'
);

export const setHeaderAction = createAction(
  '[Header Page] set check',
  props<{ check: boolean }>()
);
