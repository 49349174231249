import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalOpenApplicationComponent } from 'src/app/modal-open-application/modal-open-application.component';

@Component({
  selector: 'app-head-card',
  templateUrl: './head-card.component.html',
  styleUrls: ['./head-card.component.scss'],
})
export class HeadCardComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}
  openModal() {
    this.dialog.open(ModalOpenApplicationComponent, {
      data: {
        application: 'dashplan',
        panelClass: 'custom-dialog-container',
      },
    });
  }
}
