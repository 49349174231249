<div>
  <header class="bg-default-white d-flex border-bottom">
    <app-header
      [isHomePage]="false"
      style="background-color: #edeef0"
      class="w-100 d-flex justify-content-center border"
    ></app-header>
  </header>

  <!-- <div class="content-container ps-4 mx-auto mt-5 fs-2 title">Todos os documentos</div> -->

  <div class="row px-0 mt-1  mb-4 container mx-auto align-items-start">
    <div class="row mt-5 mb-4">
      <h4 class="fs-4 col-12 fw-medium">Todos os Documentos</h4>
    </div>

    <ng-container
      *ngFor="let module of moduleNames; let i = index"
      class="px-0"
    >
      <div *ngIf="renameModel(module)">
        <div class="row">
          <h4 class="fs-5 mt-5 col-12">{{ renameModel(module) }}</h4>
        </div>
        <div class="row mt-2">
          <ng-container *ngFor="let document of documents; let i = index">
            <div
              *ngIf="hasMatchingContentType(document, module) && document.title"
              [ngClass]="'col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 g-4'"
            >
              <app-document-card
                [title]="document.title"
                [imgPath]="document.imgPath"
                [icon]="document.icon"
                [modules]="document.modules"
                [contentTypes]="document.contentTypes"
                [isNew]="document.isNew"
                [DocumentLink]="document.documentLink"
              ></app-document-card>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
  <footer class="footer py-0 bg-default-white border-top mt-0">
    <app-footer></app-footer>
  </footer>
</div>
