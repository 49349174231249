import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHeaderState } from './header.state';

export const getHeaderSelectorsState =
  createFeatureSelector<IHeaderState>('headerReduce');

export const getHeaderSelectors = createSelector(
  getHeaderSelectorsState,
  (store: any) => {
    return store;
  }
);
