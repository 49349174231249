import { Component, Input, OnInit } from '@angular/core';
import { UsersApplicationsService } from '../users-applications.service';
import { environment } from './../../../environments/environment';
import { v4 } from 'uuid';
import { LoadingService } from '../../shared/loading.service';
import { ConfigurationsStudiesModalComponent } from '../../studies-clientes-screens/modals/configurations-studies-modal/configurations-studies-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-select-client-study',
  templateUrl: './select-client-study.component.html',
  styleUrls: ['./select-client-study.component.scss']
})
export class SelectClientStudyComponent implements OnInit {
  studiesList: any[] = [];
  filteredstudiesList: any[] = [];
  @Input()
  adminId: string;

  @Input() application: string;

  @Input()
  clientId: string;
  
  @Input()
  isPersonStudies: boolean = false;
  loading = true;
  pageSize = 5;  
  currentPage = 1;
  userName: string;
  searchTerm: '';
  numberOfPages: number = 1;

  constructor(
    public toster: ToastrService,
    public usersApplicationsService: UsersApplicationsService,
    private loadingService: LoadingService,
    private dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    if (this.clientId === undefined && this.adminId !== undefined){
      this.clientId = this.adminId
    }
    const aplicationselector = this.loadingService.getLoadingState();
    this.usersApplicationsService.getEspecificUser(this.clientId).subscribe((userlist: any) =>{
      this.userName = `${userlist.firstName} ${userlist.lastName}`
    });
    this.usersApplicationsService.getSoftwareStudies(aplicationselector, this.clientId).subscribe((studiesList: any) => {
        
        this.loading = false;
        this.studiesList = studiesList;
        this.numberOfPages = Math.ceil(this.studiesList.length / this.pageSize);

        this.filteredstudiesList = this.studiesList;

      }); 
  }
  create() {
    this.openLink(v4());
  }
  openLink(id: string) {
    const aplicationselector = this.loadingService.getLoadingState();

    this.usersApplicationsService.getAccessToken(this.clientId, aplicationselector, id)
    .subscribe((response: any) => {
      this.openFolder(this.application, response.accessToken);
    },() => {
      alert('Tivemos um problema ao abrir a pasta deste usuário.')
    })
  }
  openFolder(software: string, token: string) {

    location.href = environment.clientRedirect.replace('techfinance', software).replace('.qa.', '.') + '/folder-token/callback/' + token;
  }



  openLinkcadastro(subdomain: string, route: string) {
    if(subdomain==='login' && environment.isQa === true) {
      location.href = ('https://' + subdomain + '.' + environment.baseUrl + route).replace('login.tf', 'login');
      return;
    } 
    location.href = (environment.clientRedirect.replace('techfinance', subdomain) + route).replace('.qa.', '.').replace('login.tf', 'login');
  }

  get startIndex(): number {
    return (this.currentPage - 1) * this.pageSize;
  }

  get endIndex(): number {
    return this.currentPage * this.pageSize;
  }

  get totalPages(): number {
    return Math.ceil(this.studiesList.length / this.pageSize);
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  goToPage(page: number):void{
    this.currentPage = page;
  }
  getPages(): number[] {
    return Array.from({ length: this.numberOfPages }, (_, i) => i + 1);
  }
  searchUsers() {
    this.filteredstudiesList = this.studiesList.filter(studie =>
      studie.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    this.currentPage = 1;
  }


  
  openApplicationModal(id: string) {
    const dialogRef = this.dialog.open(ConfigurationsStudiesModalComponent, {
      disableClose: true,
      data: {
        UserId: this.clientId,
        studieId: id,
      },
      panelClass: 'custom-dialog-container',
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result.deleted){
        this.toster.success(`estudo "${result.name}" foi excluído`);

      }
      if (result.updated){
        this.toster.success(`Suas edições foram salvas`);

      }
      if (result && (result.updated || result.deleted)) {
        const currentPage = result.updated ? this.currentPage : 1;
    
        const aplicationselector = this.loadingService.getLoadingState();
        this.usersApplicationsService.getSoftwareStudies(aplicationselector, this.clientId)
          .subscribe((studiesList: any) => {
            this.studiesList = studiesList;
            this.filteredstudiesList = this.studiesList;
            this.numberOfPages = Math.ceil(this.studiesList.length / this.pageSize);
            this.currentPage = currentPage;
          });
      }
    });
    
  }
  
  
}
