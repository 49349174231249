import { Component, Inject, OnInit } from '@angular/core';
import { UsersApplicationsService } from '../../../modal-open-application/users-applications.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/loading.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-configurations-studies-modal',
  templateUrl: './configurations-studies-modal.component.html',
  styleUrls: ['./configurations-studies-modal.component.scss'],
})
export class ConfigurationsStudiesModalComponent implements OnInit {
  studieResponse: any = {};
  studyForm: FormGroup;
  loading: boolean = false;
  deleteConfirmation: boolean = false;
  closeConfirmation: boolean = false;

  constructor(
    private fb: FormBuilder,
    private usersApplicationsService: UsersApplicationsService,
    private dialog: MatDialog,
    private loadingservice: LoadingService,
    private route: Router,
    public dialogRef: MatDialogRef<ConfigurationsStudiesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { UserId: string; studieId: string }
  ) {}

  ngOnInit(): void {
    this.loading = false;
    this.studyForm = this.fb.group({
      name: ['', Validators.required],
    });
    this.usersApplicationsService
      .getSoftwareStudies(this.loadingservice.getLoadingState(), this.data.UserId)
      .subscribe((studiesList: any) => {
        const study = studiesList.find((study: any) => study._id === this.data.studieId);
        this.studieResponse = study || {};
        this.studyForm.patchValue({
          name: this.studieResponse.name,
        });
      });
  }

  ngAfterViewInit(): void {
    this.loading = true;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  deleteScreenCall(screenCall: boolean): void {
    this.deleteConfirmation = screenCall;
  }

  onSubmit() {
    if (this.studyForm.valid) {
      const updatedStudyData = {
        ...this.studieResponse,
        name: this.studyForm.get('name')?.value,
        updatedAt: new Date().toISOString(),
      };

      this.usersApplicationsService.updateStudy(this.data.studieId, updatedStudyData).subscribe(
        (response) => {
          
          this.dialogRef.close({ updated: true });
        },
        (error) => {
          console.error('Erro ao atualizar o estudo:', error);
        }
      );
    }
  }

  onDeleteStudy() {
    if (this.studieResponse._id) {
      this.usersApplicationsService.deleteStudy(this.studieResponse._id).subscribe(
        (response) => {
          
          this.dialogRef.close({ deleted: true, name: this.studieResponse.name });
        },
        (error) => {
          console.error('Erro ao excluir o estudo:', error);
        }
      );
    }
  }
}
