<div
  style="max-width: 1920px"
  class="mb-0 w-100"
  role="alert"
>
  <div class="align-items-center">
    <div class="d-flex justify-content-between">
      <div></div>
      <div class="d-flex align-items-center ">
        <div class="">
          <span
            style=" font-size: 14px; border: 1px solid #0056D033;"
            class="badge d-flex gap-1 align-items-center p-1 px-2 text-black"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 1.5C7.21442 1.5 5.95772 1.88122 4.8888 2.59545C3.81988 3.30968 2.98676 4.32484 2.49479 5.51256C2.00282 6.70028 1.87409 8.00721 2.1249 9.26809C2.3757 10.529 2.99477 11.6872 3.90381 12.5962C4.81285 13.5052 5.97104 14.1243 7.23192 14.3751C8.49279 14.6259 9.79973 14.4972 10.9874 14.0052C12.1752 13.5132 13.1903 12.6801 13.9046 11.6112C14.6188 10.5423 15 9.28558 15 8C14.9982 6.27665 14.3128 4.62441 13.0942 3.40582C11.8756 2.18722 10.2234 1.50182 8.5 1.5ZM13.9769 7.5H11.4875C11.3994 5.72125 10.8456 4.03312 9.9175 2.6875C11.0045 2.97933 11.9761 3.5969 12.7018 4.45722C13.4275 5.31753 13.8725 6.37936 13.9769 7.5ZM8.5 13.4931C7.32938 12.2262 6.61938 10.4306 6.51438 8.5H10.4856C10.3806 10.4294 9.67063 12.2262 8.5 13.4931ZM6.51438 7.5C6.61938 5.57062 7.32688 3.77375 8.5 2.50688C9.67063 3.77375 10.3806 5.56938 10.4856 7.5H6.51438ZM7.0825 2.6875C6.15438 4.03312 5.60063 5.72125 5.5125 7.5H3.02313C3.12756 6.37936 3.57251 5.31753 4.29819 4.45722C5.02387 3.5969 5.9955 2.97933 7.0825 2.6875ZM3.02313 8.5H5.5125C5.60063 10.2787 6.15438 11.9669 7.0825 13.3125C5.9955 13.0207 5.02387 12.4031 4.29819 11.5428C3.57251 10.6825 3.12756 9.62064 3.02313 8.5ZM9.9175 13.3125C10.8456 11.965 11.3994 10.2769 11.4875 8.5H13.9769C13.8725 9.62064 13.4275 10.6825 12.7018 11.5428C11.9761 12.4031 11.0045 13.0207 9.9175 13.3125Z"
                fill="#001847"
              />
            </svg>
            Ecossistema
          </span>
        </div>

        <span class="p-2">
          Conheça o CRM projetado para Wealth Planners que agora está no Ecossistema TechFinance!
        </span>
        <a
          class="d-flex gap-2 align-items-center btn font-weight-bold"
          target="_blank"
          ><span>Acessar </span>
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.3538 8.35378L9.85375 12.8538C9.75993 12.9476 9.63268 13.0003 9.5 13.0003C9.36732 13.0003 9.24007 12.9476 9.14625 12.8538C9.05243 12.76 8.99972 12.6327 8.99972 12.5C8.99972 12.3674 9.05243 12.2401 9.14625 12.1463L12.7931 8.50003H3C2.86739 8.50003 2.74021 8.44736 2.64645 8.35359C2.55268 8.25982 2.5 8.13264 2.5 8.00003C2.5 7.86743 2.55268 7.74025 2.64645 7.64648C2.74021 7.55271 2.86739 7.50003 3 7.50003H12.7931L9.14625 3.85378C9.05243 3.75996 8.99972 3.63272 8.99972 3.50003C8.99972 3.36735 9.05243 3.2401 9.14625 3.14628C9.24007 3.05246 9.36732 2.99976 9.5 2.99976C9.63268 2.99976 9.75993 3.05246 9.85375 3.14628L14.3538 7.64628C14.4002 7.69272 14.4371 7.74786 14.4623 7.80856C14.4874 7.86926 14.5004 7.93433 14.5004 8.00003C14.5004 8.06574 14.4874 8.13081 14.4623 8.1915C14.4371 8.2522 14.4002 8.30735 14.3538 8.35378Z"
              fill="#001E2E"
            />
          </svg>
        </a>
      </div>
      <div>
        <button
          type="button"
          (click)="closeDisplayNotification()"
          class="close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>
