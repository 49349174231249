<div class="content" *ngIf="loading  && !deleteConfirmation && !closeConfirmation">
  <div class="row">
    <div class="col-10">
      <h1 mat-dialog-title class="title">Informações do cliente</h1>
    </div>
    <div class="col-1 close-button" (click)="closeConfirmation = true">
      <svg
        width="18"
        height="18"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2807 14.2193C15.3504 14.289 15.4056 14.3717 15.4433 14.4628C15.4811 14.5538 15.5005 14.6514 15.5005 14.7499C15.5005 14.8485 15.4811 14.9461 15.4433 15.0371C15.4056 15.1281 15.3504 15.2109 15.2807 15.2806C15.211 15.3502 15.1283 15.4055 15.0372 15.4432C14.9462 15.4809 14.8486 15.5003 14.7501 15.5003C14.6515 15.5003 14.5539 15.4809 14.4629 15.4432C14.3718 15.4055 14.2891 15.3502 14.2194 15.2806L8.00005 9.06024L1.78068 15.2806C1.63995 15.4213 1.44907 15.5003 1.25005 15.5003C1.05103 15.5003 0.860156 15.4213 0.719426 15.2806C0.578695 15.1398 0.499634 14.949 0.499634 14.7499C0.499634 14.5509 0.578695 14.36 0.719426 14.2193L6.93974 7.99993L0.719426 1.78055C0.578695 1.63982 0.499634 1.44895 0.499634 1.24993C0.499634 1.05091 0.578695 0.860034 0.719426 0.719304C0.860156 0.578573 1.05103 0.499512 1.25005 0.499512C1.44907 0.499512 1.63995 0.578573 1.78068 0.719304L8.00005 6.93962L14.2194 0.719304C14.3602 0.578573 14.551 0.499512 14.7501 0.499512C14.9491 0.499512 15.1399 0.578573 15.2807 0.719304C15.4214 0.860034 15.5005 1.05091 15.5005 1.24993C15.5005 1.44895 15.4214 1.63982 15.2807 1.78055L9.06036 7.99993L15.2807 14.2193Z"
          fill="#191C1E"
        />
      </svg>
    </div>
  </div>
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div class="form-group">
        <label for="nomeCliente mb-0">Nome do cliente </label>
        <input
          formControlName="name"
          type="text"
          class="form-control"
          id="nomeCliente"
          placeholder="Nome do cliente"
        />
      </div>

      <div class="form-group">
        <label for="emailCliente mb-0">E-mail</label>
        <input
          formControlName="email"
          type="email"
          class="form-control"
          id="emailCliente"
          placeholder="E-mail do cliente"
        />
      </div>

      <div class="form-group">
        <label for="telefoneCliente mb-0">Telefone do cliente</label>
        <input
          formControlName="phone"
          type="tel"
          class="form-control"
          id="telefoneCliente"
          placeholder="Telefone do cliente"
          (input)="editNumberType($event)"
        />
      </div>
      <hr class="mb-3" />
      <div *ngIf="errorMessage" class="alert alert-danger my-3">
        {{ errorMessage }}
      </div>
      <div class="row">
        <div class="col-6 mb-0">
          <p class="title-response">Última atualização</p>
          <p class="subtitle mb-0">
            {{ userResponse.updatedAt | date : "dd/MM/yyyy HH:mm" }}
          </p>
        </div>

        <div class="col-6 mb-0">
          <p class="title-response">Criação</p>
          <p class="subtitle mb-0">
            {{ userResponse.createdAt | date : "dd/MM/yyyy HH:mm" }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <button
            class="btn btn-outline me-2 exclude-text"
            style="width: 30%"
            (click)="deleteScreenCall(true)"
          >
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 mb-1"
            >
              <path
                d="M17.25 3.5H13.5V2.75C13.5 2.15326 13.2629 1.58097 12.841 1.15901C12.419 0.737053 11.8467 0.5 11.25 0.5H6.75C6.15326 0.5 5.58097 0.737053 5.15901 1.15901C4.73705 1.58097 4.5 2.15326 4.5 2.75V3.5H0.75C0.551088 3.5 0.360322 3.57902 0.21967 3.71967C0.0790178 3.86032 0 4.05109 0 4.25C0 4.44891 0.0790178 4.63968 0.21967 4.78033C0.360322 4.92098 0.551088 5 0.75 5H1.5V18.5C1.5 18.8978 1.65804 19.2794 1.93934 19.5607C2.22064 19.842 2.60218 20 3 20H15C15.3978 20 15.7794 19.842 16.0607 19.5607C16.342 19.2794 16.5 18.8978 16.5 18.5V5H17.25C17.4489 5 17.6397 4.92098 17.7803 4.78033C17.921 4.63968 18 4.44891 18 4.25C18 4.05109 17.921 3.86032 17.7803 3.71967C17.6397 3.57902 17.4489 3.5 17.25 3.5ZM6 2.75C6 2.55109 6.07902 2.36032 6.21967 2.21967C6.36032 2.07902 6.55109 2 6.75 2H11.25C11.4489 2 11.6397 2.07902 11.7803 2.21967C11.921 2.36032 12 2.55109 12 2.75V3.5H6V2.75ZM15 18.5H3V5H15V18.5ZM7.5 8.75V14.75C7.5 14.9489 7.42098 15.1397 7.28033 15.2803C7.13968 15.421 6.94891 15.5 6.75 15.5C6.55109 15.5 6.36032 15.421 6.21967 15.2803C6.07902 15.1397 6 14.9489 6 14.75V8.75C6 8.55109 6.07902 8.36032 6.21967 8.21967C6.36032 8.07902 6.55109 8 6.75 8C6.94891 8 7.13968 8.07902 7.28033 8.21967C7.42098 8.36032 7.5 8.55109 7.5 8.75ZM12 8.75V14.75C12 14.9489 11.921 15.1397 11.7803 15.2803C11.6397 15.421 11.4489 15.5 11.25 15.5C11.0511 15.5 10.8603 15.421 10.7197 15.2803C10.579 15.1397 10.5 14.9489 10.5 14.75V8.75C10.5 8.55109 10.579 8.36032 10.7197 8.21967C10.8603 8.07902 11.0511 8 11.25 8C11.4489 8 11.6397 8.07902 11.7803 8.21967C11.921 8.36032 12 8.55109 12 8.75Z"
                fill="#B4280C"
              />
            </svg>

            Excluir
          </button>
          <button
            type="submit"
            class="btn save-edits"
            style="width: 30%"
            [disabled]="!userForm.dirty || userForm.invalid"
            focus
          >
            Salvar Edições
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="content-exclude" *ngIf="deleteConfirmation == true">
  <p class="delete-title">Excluir cliente selecionado?</p>
  <p class="delete-content">
    Esta é uma ação irreversível. Todos os dados do cliente “Fulano de Tal”
    serão apagados do sistema e não poderão ser recuperados.
  </p>
  <div class="row">
    <div class="col-6 d-flex justify-content-start">
      <button class="btn cancel-button w-100" (click)="deleteScreenCall(false)">
        Cancelar
      </button>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button (click)="deleteUser()" class="btn exclude-button w-100">
        <svg
          width="18"
          height="20"
          viewBox="0 0 18 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mr-2 mb-1"
        >
          <path
            d="M17.25 3.5H13.5V2.75C13.5 2.15326 13.2629 1.58097 12.841 1.15901C12.419 0.737053 11.8467 0.5 11.25 0.5H6.75C6.15326 0.5 5.58097 0.737053 5.15901 1.15901C4.73705 1.58097 4.5 2.15326 4.5 2.75V3.5H0.75C0.551088 3.5 0.360322 3.57902 0.21967 3.71967C0.0790178 3.86032 0 4.05109 0 4.25C0 4.44891 0.0790178 4.63968 0.21967 4.78033C0.360322 4.92098 0.551088 5 0.75 5H1.5V18.5C1.5 18.8978 1.65804 19.2794 1.93934 19.5607C2.22064 19.842 2.60218 20 3 20H15C15.3978 20 15.7794 19.842 16.0607 19.5607C16.342 19.2794 16.5 18.8978 16.5 18.5V5H17.25C17.4489 5 17.6397 4.92098 17.7803 4.78033C17.921 4.63968 18 4.44891 18 4.25C18 4.05109 17.921 3.86032 17.7803 3.71967C17.6397 3.57902 17.4489 3.5 17.25 3.5ZM6 2.75C6 2.55109 6.07902 2.36032 6.21967 2.21967C6.36032 2.07902 6.55109 2 6.75 2H11.25C11.4489 2 11.6397 2.07902 11.7803 2.21967C11.921 2.36032 12 2.55109 12 2.75V3.5H6V2.75ZM15 18.5H3V5H15V18.5ZM7.5 8.75V14.75C7.5 14.9489 7.42098 15.1397 7.28033 15.2803C7.13968 15.421 6.94891 15.5 6.75 15.5C6.55109 15.5 6.36032 15.421 6.21967 15.2803C6.07902 15.1397 6 14.9489 6 14.75V8.75C6 8.55109 6.07902 8.36032 6.21967 8.21967C6.36032 8.07902 6.55109 8 6.75 8C6.94891 8 7.13968 8.07902 7.28033 8.21967C7.42098 8.36032 7.5 8.55109 7.5 8.75ZM12 8.75V14.75C12 14.9489 11.921 15.1397 11.7803 15.2803C11.6397 15.421 11.4489 15.5 11.25 15.5C11.0511 15.5 10.8603 15.421 10.7197 15.2803C10.579 15.1397 10.5 14.9489 10.5 14.75V8.75C10.5 8.55109 10.579 8.36032 10.7197 8.21967C10.8603 8.07902 11.0511 8 11.25 8C11.4489 8 11.6397 8.07902 11.7803 8.21967C11.921 8.36032 12 8.55109 12 8.75Z"
            fill="#FFFFFF"
          />
        </svg>
        Excluir Cliente
      </button>
    </div>
  </div>
</div>




<div class="content-confirm" *ngIf=" closeConfirmation">
  <p class="delete-title">Descartar edições não salvas?</p>
  <p class="delete-content">
    Descartar edições não salvas?
  </p>
  <div class="row">
    <div class="col-6 d-flex justify-content-start">
      <button class="btn cancel-button w-100" (click)="closeConfirmation = false">
        Cancelar
      </button>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <button (click)="closeModal()" class="btn confirm-button w-100">
       Descartar Edições 
      </button>
    </div>
  </div>
</div>