<div class="page-container">
  <header class="bg-default-white d-flex shadow border-bottom">
    <app-header
      style="background-color: #edeef0"
      class="w-100 d-flex justify-content-center border"
    ></app-header>
  </header>

  <div class="d-flex justify-content-center mt-3 row content-size">
    <div class="row col-9 d-flex justify-content-center mb-5 bar-size">
      <div
        class="col-12 d-flex justify-content-center button-top mt-3 mb-1 pr-4"
      >
        <img
          src="assets/images/home-icon.png"
          class="mt-1"
          width="18px"
          height="18px"
        />
        <a class="mt-1 ml-2 top-button" routerLink="/">
          <div>Início</div>
        </a>
      </div>

      <div class="select-modal card rounded-3 p-3">
        <h4
          class="border-start border-4 border-success mt-mobile ps-2 mb-4 title"
        >
          Cadastre um novo cliente
        </h4>

        <div class="align-items-center col-12">
          <form (ngSubmit)="onSubmit()" class="row">
            <div class="col-12">
              <label for="inputName" class="form-label title-input">Nome</label>
              <input
                type="text"
                class="form-control input-signup"
                id="inputName"
                [(ngModel)]="fullName"
                name="fullName"
                (input)="validateFullName()"
                required
              />
              <div *ngIf="!isFullNameValid" class="text-danger mt-1">
                Nome completo com sobrenome é obrigatório.
              </div>
            </div>

            <div class="col-12">
              <label for="inputEmail" class="form-label title-input"
                >E-mail</label
              >
              <input
                type="email"
                class="form-control input-signup"
                id="inputEmail"
                [(ngModel)]="email"
                name="email"
                required
              />
              <div *ngIf="!email" class="text-danger mt-1">
                E-mail é obrigatório.
              </div>
            </div>

            <div class="col-12">
              <label for="inputPhone" class="form-label title-input"
                >Telefone</label
              >
              <input
                type="tel"
                class="form-control input-signup"
                id="inputPhone"
                [(ngModel)]="phone"
                (keypress)="onlyAllowNumbers($event)"
                name="phone"
                (input)="formatPhoneNumber()"
                maxlength="15"
              />
            </div>

            <div class="col-12">
              <div *ngIf="errorMessage" class="alert alert-danger mt-3">
                {{ errorMessage }}
              </div>
            </div>

            <div class="col-12 mb-3 mt-3">
              <button
                type="submit"
                class="btn submit-btn mt-3 w-100"
                [disabled]="!fullName || !email || !isFullNameValid"
              >
                Finalizar Cadastro
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer py-1 bg-default-white border-top mt-auto">
    <app-footer class="content-container"></app-footer>
  </footer>
</div>
