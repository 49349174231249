import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})
export class MaterialComponent implements OnInit {
  @Input() haveLateralName: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
