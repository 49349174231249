import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss']
})
export class DocumentCardComponent implements OnInit {
  
  @Input() title: string = '';
  @Input() imgPath: string = '';
  @Input() icon: string;
  @Input() modules: string[] = [];
  @Input() contentTypes: string[] = [];
  @Input() isNew: boolean = false;
  @Input() DocumentLink: string;
  constructor(private sanitizer: DomSanitizer) { 
   }
  ngOnInit(): void {

  }
  getSvg(svgStr: string) {
    return this.sanitizer.bypassSecurityTrustHtml(svgStr);
  }}
