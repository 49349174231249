import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    public httpClient: HttpClient,  
    public oidcSecurityService: OidcSecurityService,
  ) { }
  create(usuario: { fullName: String; email: String; password: String, selectusActive: string, origin: string }) {
    return this.httpClient.post(environment.url + 'users/create', {
      fullName: usuario.fullName,
      email: usuario.email.toLowerCase(),
      password: usuario.password,
      origin: usuario.origin,
      selectusActive: usuario.selectusActive,
    });
  }
 
  get token() {
    return this.oidcSecurityService.getToken();
  }
  profile() {
    return this.httpClient.get(environment.url + 'users/member/profile', {
      headers: {
        Authorization: 'Bearer ' + this.token,
      },
    });
  }
}
