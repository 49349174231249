<a
  *ngIf="!isModal"
  [ngClass]="block ? 'block' : null"
  class="d-flex hover flex-column align-items-center text-center no-underline"
  [href]="link"
  target="_blank"
>
  <div class="icon-box-small p-1 mt-2 shadow-sm rounded">
    <img
      width="50"
      height="50"
      class="card-icon-small"
      [src]="imgPath"
      alt="Card Icon"
    />
  </div>
  <span class="h6 title mt-2 text-center d-inline-block text-wrap" style="width: 110px;  height: 40px;">{{ title }}</span>
</a>

<a
  *ngIf="isModal"
  [ngClass]="block ? 'block' : null"
  class="d-flex hover card-modal flex-column align-items-center text-center no-underline"
  [href]="link"
  target="_blank"
>
  <div class="icon-box-small p-1 border shadow-sm rounded">
    <img class="card-icon-small" width="50"
    height="50" [src]="imgPath" alt="Card Icon" />
  </div>
  <span class="h6 title mt-2 text-center d-inline-block text-wrap" style="width: 110px; height: 40px;">{{ title }}</span>
</a>
