
<section>
  <div class="d-flex">
    <div *ngIf="haveLateralName">
      <p style="font-size: 16px; width: 100px;" class="fw-medium">Materiais</p>
    </div>
    <div class="card-menu m-2 p-2 px-3 rounded">
      <app-card-link
      data-bs-toggle="tooltip"
        class="material-card"
        style="width: 100%"
        title="Documentos Oficiais"
        [subtitle]="'Materiais Complementares'"
        [imgPath]="'/assets/images/homeTF/logo-tf.svg'"
        [link]="'https://pg.empreenderdinheiro.com.br/documentos-oficiais/'"
      ></app-card-link>
    </div>
  </div>
</section>

