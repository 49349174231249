import * as svgs from './svg';
import * as categories from './categorias';
import * as modules from './modulos';

const imgPath = '/assets/documents-page/document-thumbnail/';
export const documents = [
  {
    title: 'Protegeu, Ganhou! - Regulamento da Campanha',
    imgPath: imgPath + 'protegeu_ganhou.png',
    icon: svgs.PDF,
    modules: [categories.SEGUROS],
    contentTypes: [modules.MODULO.COMPLEMENTAR, modules.MODULO.M6],
    isNew: true,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2024/10/Regulamento-da-campanha-Protegeu-ganhou-2024.pdf',
  },
  {
    title: 'Contrato de Prestação de Serviços',
    imgPath: imgPath + 'contrato_de_prestacao.png',
    icon: svgs.DOC,
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.COMPLEMENTAR],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2024%2F07%2FModelo-de-Contrato-Wealth-Planner-Cliente-Final.docx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Teaser "O Fim da Vida"',
    imgPath: imgPath + 'TEASER_O_FIM_DA_VIDA.png',
    icon: svgs.VIDEO,
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.COMPLEMENTAR, modules.MODULO.M1],
    isNew: false,
    documentLink:
      'https://empreenderdinheiro.com.br/wp-content/uploads/2024/01/teaser_-_o_fim_da_vida-1080p.mp4?_gl=1*ck3ldc*_gcl_au*MTM0MjUyODAwNC4xNzMwNDA0NDE2*_ga*NTk5NjMwNzkxLjE3Mjk3Nzc5MjY.*_ga_LZY60LQKGV*MTczMTk1MDc0Ni4zMi4wLjE3MzE5NTA3NDYuNjAuMC4w&_ga=2.23183613.698953719.1731948405-599630791.1729777926',
  },
  {
    title: 'Modelo Apresentações TF',
    imgPath: imgPath + 'FUNDOS_DE_PREVIDENCIA.png',
    icon: svgs.PPT,
    modules: [categories.SEGUROS],
    contentTypes: [modules.MODULO.COMPLEMENTAR, modules.MODULO.M7],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2024/09/Fundos-de-Previdencia-Recomendados.pdf',
  },
  {
    title: 'Modelo Apresentações TF',
    imgPath: imgPath + 'MODELO_DE_APRESENTACAO.png',
    icon: svgs.PPT, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.COMPLEMENTAR],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fempreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F07%2FBase-de-Apresentacao-TechFinance-1.pptx%3F_gl%3D1*9on4k9*_gcl_au*MTM0MjUyODAwNC4xNzMwNDA0NDE2*_ga*NTk5NjMwNzkxLjE3Mjk3Nzc5MjY.*_ga_LZY60LQKGV*MTczMTk1MDc0Ni4zMi4xLjE3MzE5NTEyMjQuNjAuMC4w%26_ga%3D2.118670158.698953719.1731948405-599630791.1729777926&wdOrigin=BROWSELINK',
  },
  {
    title: 'Curva de Receita e Agenda - WP',
    imgPath: imgPath + 'TABELA_CURVA.png',
    icon: svgs.XLS, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M1,modules.MODULO.M2],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F11%2FCurva-de-Receita-e-Agenda-WP.xlsx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Mensagem Oficial aos Familiares',
    imgPath: imgPath + 'MENSAGEM_AOS_FAMLIARES.png',
    icon: svgs.VIDEO, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M1],
    isNew: false,
    documentLink:
      'https://vimeo.com/showcase/mensagemfwp',
  },
  {
    title: 'Script de Convite da Consultoria Express',
    imgPath: imgPath + 'SCRIPT_DE_CONVITE.png',
    icon: svgs.PDF, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M2,modules.MODULO.M3],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/11/Script-de-Convite-da-Consultoria-Express.pdf',
  },
  {
    title: 'Projeto 200 - Wealth Planner ED',
    imgPath: imgPath + 'PROJETO_200.png',
    icon: svgs.XLS, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M2],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F11%2FProjeto-200-Wealth-Planner-ED.xlsx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Domine o Guia TF - Podcast',
    imgPath: imgPath + 'DOMINE_O_GUIA.png',
    icon: svgs.PODCAST, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M3],
    isNew: false,
    documentLink:
      'https://soundcloud.com/user-902782380/aula-07-domine-o-guia-tf-1080p-1?si=7d5791fecadb488e850cec44f30e8087&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing',
  },
  {
    title: 'Modelo de Proposta Comercial',
    imgPath: imgPath + 'MODELO_DE_PROPOSTA.png',
    icon: svgs.PPT, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M3],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2024%2F02%2FModelo-de-Proposta-Oficial-Wealth-Planner-TF.pptx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Mandamentos da Prospecção & Scripts',
    imgPath: imgPath + 'MANDAMENTOS_E_PROSPECÇÃO.png',
    icon: svgs.PDF, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M3],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2024/06/Mandamentos-da-Prospecc%CC%A7a%CC%83o-Scripts-1.pdf',
  },
  {
    title: 'Scripts de Recomendações',
    imgPath: imgPath + 'SCRIPT_DE_RECOMENDACOES.png',
    icon: svgs.PDF, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M3],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2024/06/Scripts-de-Recomendac%CC%A7o%CC%83es-1.pdf',
  },
  {
    title: 'Mindmap Orçamento',
    imgPath: imgPath + 'MINDMAP_ORCAMENTO.png',
    icon: svgs.PPT, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M4],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F11%2FMindmap-Orcamento.pptx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Diagnóstico Orçamentário Preciso (Fluxo Futuro)',
    imgPath: imgPath + 'DIAGNOSTICO_ORCAMENTO_PRECISO.png',
    icon: svgs.XLS, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M4],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F11%2FDiagnostico-Preciso-Fluxo-Futuro.xlsx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Preciso Fluxo Futuro (Versão Planilha)',
    imgPath: imgPath + 'PREVISAO_FLUXO_FUTURO.png',
    icon: svgs.XLS, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M4],
    isNew: false,
    documentLink:
      'https://empreenderdinheiro.com.br/wp-content/uploads/2024/07/PRECISO_9.xlsb?_gl=1*1mnt16*_gcl_au*MTM0MjUyODAwNC4xNzMwNDA0NDE2*_ga*NTk5NjMwNzkxLjE3Mjk3Nzc5MjY.*_ga_LZY60LQKGV*MTczMTk1MDc0Ni4zMi4xLjE3MzE5NTMzOTEuNjAuMC4w&_ga=2.81888081.698953719.1731948405-599630791.1729777926',
  },
  {
    title: 'Preciso Fluxo Futuro (Versão Planilha)',
    imgPath: imgPath + 'COMO_LIBERAR_ACESSO_AO_PRECISO.png',
    icon: svgs.PDF, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M4],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/11/Como-Liberar-Acesso-ao-Preciso.pdf',
  },
  {
    title: 'Planejador de Objetivos',
    imgPath: imgPath + 'PLANEJADOR_DE_OBJETIVOS.png',
    icon: svgs.XLS, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M4],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F11%2FWP-Planejador-Objetivos-.xlsx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Taleb Vs Kahneman - Podcast',
    imgPath: imgPath + 'TALEB_VS_KAHNEMAN.png',
    icon: svgs.SPOTIFY, 
    modules: [categories.INVESTIMENTOS],
    contentTypes: [modules.MODULO.M5],
    isNew: false,
    documentLink:
      'https://open.spotify.com/episode/4AyEaqPevSUbIHqJqzPLmK?si=OLDZGDP2S6227Prx7kgEhw&nd=1&dlsi=a18043ee79ca4b46',
  },
  {
    title: 'Regras de Uso da Mesa Proprietária',
    imgPath: imgPath + 'REGRAS_DE_USO_MESA_PRIORITARIA.png',
    icon: svgs.PDF, 
    modules: [categories.INVESTIMENTOS],
    contentTypes: [modules.MODULO.M5],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2024/03/Regras-de-Uso-da-Mesa-Proprietaria-v.3.pdf',
  },
  {
    title: 'Simulação de Receita Bruta em Seguros',
    imgPath: imgPath + 'SIMULACAO_RECEITA_BRUTA.png',
    icon: svgs.XLS, 
    modules: [categories.SEGUROS],
    contentTypes: [modules.MODULO.M6, modules.MODULO.M10],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F12%2FSimulacao-de-remuneracao-BRUTA-Wealth-Planner-ED-Seguros.xlsx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Tabela de Remuneração - ED Seguros',
    imgPath: imgPath + 'TABELA_DE_REMUNERACAO.png',
    icon: svgs.PDF, 
    modules: [categories.SEGUROS],
    contentTypes: [modules.MODULO.M6, modules.MODULO.M10],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2024/01/Tabela-de-Remunerac%CC%A7a%CC%83o-WP-ED-SEGUROS.pdf',
  },
  {
    title: 'Lista de Doenças Graves Passíveis de Cobertura por Seguro',
    imgPath: imgPath + 'LISTA_DE_DOENCAS.png',
    icon: svgs.PDF, 
    modules: [categories.SEGUROS],
    contentTypes: [modules.MODULO.M6],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Lista-das-Doencas-Graves-que-sao-passiveis-de-cobertura-do-seguro.pdf',
  },
  {
    title: 'Política de Estorno da Remuneração',
    imgPath: imgPath + 'POLITICA_DE_ESTORNO.png',
    icon: svgs.PDF, 
    modules: [categories.SEGUROS],
    contentTypes: [modules.MODULO.M6],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2024/01/Politica-de-Estorno-da-remuneracao-de-seguros-de-vida-e-previdencia-Modelo.pdf',
  },
  {
    title: 'Roteiro para Decisões Imobiliárias Lucrativas',
    imgPath: imgPath + 'ROTEIRO_PARA_DECISOES_IMOBILIARIAS.png',
    icon: svgs.XLS, 
    modules: [categories.CREDITO],
    contentTypes: [modules.MODULO.M8],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F12%2FRoteiro-Decisoes-Imobiliarias-PFW-ED.xlsx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Documentos do Projeto Imobiliário',
    imgPath: imgPath + 'DOCUMENTOS_DO_PROJETO_IMOBILIARIO.png',
    icon: svgs.DOC, 
    modules: [categories.CREDITO],
    contentTypes: [modules.MODULO.M8],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F12%2FDocumentos-do-Projeto-Imobiliario-Compras-na-Planta-v.1.docx&wdOrigin=BROWSELINK',
  },
  {
    title: 'CNAEs Wealth Planner',
    imgPath: imgPath + 'CNAES_WEALTH_PLANNER.png',
    icon: svgs.PDF, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M10],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2024/01/CNAEs-WP-v2.pdf',
  },
  {
    title: 'Tabela de Referência Gestão',
    imgPath: imgPath + 'TABELA_DE_REFERENCIA_GESTAO.png',
    icon: svgs.XLS, 
    modules: [categories.SEGUROS],
    contentTypes: [modules.MODULO.M10],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F12%2FTabela-de-Referencia-Gestao.xlsx&wdOrigin=BROWSELINK',
  },

  {
    title: 'Planilha de Simulações',
    imgPath: imgPath + 'PLANILHA_DE_SIMULACOES.png',
    icon: svgs.XLS, 
    modules: [categories.INVESTIMENTOS],
    contentTypes: [modules.MODULO.M10],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F12%2FSimulacoes-Remuneracoes-Cart.-Adm.xlsx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Tabela de Remuneração - Hub de Crédito',
    imgPath: imgPath + 'TABELA_DE_REMUNERACAO_HUB_DE_CREDITO.png',
    icon: svgs.XLS, 
    modules: [categories.CREDITO],
    contentTypes: [modules.MODULO.M10],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2024%2F03%2FTabela-de-Remuneracao-Hub-de-Credito-TF.xlsx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Diagnóstico Financeiro',
    imgPath: imgPath + 'DIAGNOSTICO_FINANCEIRO.png',
    icon: svgs.PDF, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M11],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Diagnostico-Financeiro-WP-v10.pdf',
  },
  {
    title: 'Diagnóstico Financeiro',
    imgPath: imgPath + 'DIAGNOSTICO_FINANCEIRO.png',
    icon: svgs.DOC, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M11],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F12%2FDiagnostico-Financeiro-WP-v10.docx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Checklist R1',
    imgPath: imgPath + 'CHECKLIST_R1.png',
    icon: svgs.PDF, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M11],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Checklist-R1.pdf',
  },
  {
    title: 'Checklist R2',
    imgPath: imgPath + 'CHECKLIST_R2.png',
    icon: svgs.PDF, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M11],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Checklist-R2.pdf',
  },
  {
    title: 'Checklist R3',
    imgPath: imgPath + 'CHECKLIST_R3.png',
    icon: svgs.PDF, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M11],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Checklist-R3.pdf',
  },
  {
    title: 'Financial Life Plan',
    imgPath: imgPath + 'FINANCIAL_LIFE_PLAN.png',
    icon: svgs.PPT, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M11],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2023%2F12%2FWP-Financial-Life-Plan-Nome-Sobrenome-DD.MM_.AAAA_.pptx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Checklist Cerimônia de Entrega',
    imgPath: imgPath + 'CHECKLIST_CERIMONIA_DE_ENTREGA.png',
    icon: svgs.PDF, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M11],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Checklist-Cerimonia-de-Entrega.pdf',
  },
  {
    title: 'Método 1 - Script de Relacionamento',
    imgPath: imgPath + 'METODO_1_SCRIPT_DE_RELACIONAMENTO.png',
    icon: svgs.PDF, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M12],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Script-Metodo-1.pdf',
  },
  {
    title: 'Método 4 - Script de Relacionamento',
    imgPath: imgPath + 'METODO_4_SCRIPT_DE_RELACIONAMENTO.png',
    icon: svgs.PDF, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M12],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Script-Metodo-4.pdf',
  },
  {
    title: 'Método 12 - Script de Relacionamento',
    imgPath: imgPath + 'METODO_12_SCRIPT_DE_RELACIONAMENTO.png',
    icon: svgs.PDF, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M12],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Script-Metodo-12.pdf',
  },
  {
    title: 'Intervalo Ótimo de Carteira - Script de Relacionamento',
    imgPath: imgPath + 'INTERVALO_OTIMO_DE_CARTEIRA.png',
    icon: svgs.PDF, 
    modules: [categories.INVESTIMENTOS],
    contentTypes: [modules.MODULO.M13],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Script-Intervalo-otimo-de-carteira.pdf',
  },
  {
    title: 'Mini Express',
    imgPath: imgPath + 'MINI_EXPRESS.png',
    icon: svgs.PPT, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M13],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2024%2F06%2FMiniExpress.pptx&wdOrigin=BROWSELINK',
  },
  {
    title: 'Mini Express',
    imgPath: imgPath + 'MINI_EXPRESS_PDF.png',
    icon: svgs.PDF, 
    modules: [categories.PLANEJAMENTOFINANCEIRO],
    contentTypes: [modules.MODULO.M13],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/ROTEIRO-DE-EXECUCAO-DA-MINI-EXPRESS.pdf',
  },
  {
    title: 'Script de Ligação para Reunião com RH',
    imgPath: imgPath + 'SCRIPT_DE_LIGACAO.png',
    icon: svgs.PDF, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M13],
    isNew: false,
    documentLink:
      'https://pg.empreenderdinheiro.com.br/wp-content/uploads/2023/12/Script-de-Ligacao-para-reuniao-com-RH.pdf',
  },
  {
    title: 'CRM Wealth Planner',
    imgPath: imgPath + 'CRM_WEALTH_PLANNER.png',
    icon: svgs.NOTION, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M13],
    isNew: false,
    documentLink:
      'https://friendly-cod-edb.notion.site/0391ad855d6b46af96fe7b70affe96b1?v=dbd8c4ab1d074288acb17dfc851767bf&pvs=4',
  },
  {
    title: 'Apresentação Convênio Coporativo',
    imgPath: imgPath + 'APRESENTACAO_CONVENIO_CORPORATIVO.png',
    icon: svgs.PPT, 
    modules: [categories.VENDAS],
    contentTypes: [modules.MODULO.M13],
    isNew: false,
    documentLink:
      'https://view.officeapps.live.com/op/view.aspx?src=https%3A%2F%2Fpg.empreenderdinheiro.com.br%2Fwp-content%2Fuploads%2F2024%2F03%2FApresentac%25CC%25A7a%25CC%2583o-Conve%25CC%2582nio-Corporativo.pptx&wdOrigin=BROWSELINK',
  },
];
