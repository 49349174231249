import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  showDisplayNotification = true;
  
  constructor() { }

  ngOnInit(): void {
    this.initializeNotificationDisplay();
  }

  openDisplayNotification(): void {
    localStorage.setItem('show_display_notification', 'true');
    this.showDisplayNotification = true;
  }

  closeDisplayNotification(): void {
    localStorage.setItem('show_display_notification', 'false');
    this.showDisplayNotification = false;
  }

  private initializeNotificationDisplay(): void {
    const showDisplayNotification = localStorage.getItem('show_display_notification');
    console.log(showDisplayNotification)
    if (showDisplayNotification == null || showDisplayNotification == 'true') {
      console.log("ola amigos")
      this.openDisplayNotification();
    } else {
      this.closeDisplayNotification();
    }
  }
}
