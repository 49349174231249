import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'milionMoney',
})
export class MilionMoneyPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (typeof value !== `number`) {
      value = parseFloat(value);
    }
    value = value / 1000;
    if (value > 999999999) {
      return (value / 1000000000).toFixed(2).replace('.', ',') + ' BI';
    }
    if (value > 999999) {
      return (value / 1000000).toFixed(2).replace('.', ',') + ' MI';
    }
    if (value > 999) {
      return (value / 1000).toFixed(2) + ' MIL';
    }
    return value;
  }
}
