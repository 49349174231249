import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getHeaderSelectorsState } from '../header/store/header.selectors';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  @Input() haveLateralName: boolean = false;

  check$: Observable<any>;
  storeData: any

  constructor( private store: Store) { }

  ngOnInit(): void {
    this.getStates()
  }

  getStates() {
    this.check$ = this.store.select(getHeaderSelectorsState);
    this.check$.subscribe((value) => {
      this.storeData = {
        ...(this.storeData = value)
      }
    });
  }
}
