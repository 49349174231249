import { Component, OnInit } from '@angular/core';
import * as svgs from '../svg';
import * as categories from '../categorias';
import * as modules from '../modulos';
import { documents } from '../documentos';
import { MODULO_NOMES } from '../modulos'; // Importando a lista de módulos

@Component({
  selector: 'app-documentos-oficiais',
  templateUrl: './documentos-oficiais.component.html',
  styleUrls: ['./documentos-oficiais.component.scss']
})
export class DocumentosOficiaisComponent implements OnInit {
  SVGIcon: any;
  categoriesConfig: any;
  ModulesConfig: any;
  documents: any;
  documentTitle: string = 'Complementar'
  moduleNames: any;
  constructor() {}

  ngOnInit(): void {
    this.moduleNames = MODULO_NOMES;
    console.log(this.moduleNames)
    this.documents = documents;
    this.SVGIcon = svgs;
    this.categoriesConfig = categories;
    this.ModulesConfig = modules;
  }
  hasMatchingContentType(document: any, module: string): boolean {
    return document?.contentTypes?.some(contentType => contentType.name === module);
  }
  renameModel(model: string): string | boolean {
    if (model === 'Complementar') {
      return model; 
    }
  
    const hasDocuments = this.documents.some(document =>
      this.hasMatchingContentType(document, model)
    );
  
    if (hasDocuments) {
      return model.replace(/^M(\d+):/, 'Módulo $1:');
    }
  
    return false;
  }
  
}
  
