import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getHeaderSelectorsState } from '../header/store/header.selectors';

@Component({
  selector: 'app-all-icons',
  templateUrl: './all-icons.component.html',
  styleUrls: ['./all-icons.component.scss']
})
export class AllIconsComponent implements OnInit {
  
  check$: Observable<any>;
  storeData: any

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.getStates()
  }

  getStates() {
    this.check$ = this.store.select(getHeaderSelectorsState);
    this.check$.subscribe((value) => {
      this.storeData = {
        ...(this.storeData = value)
      }
    });
  }
}
