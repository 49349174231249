const MODULO = {
  COMPLEMENTAR: {
    number: 0,
    name: 'Complementar',
    textcolor: '#43474E',
    backgroundcolor: '#E1E2E5',
  },
  M1: {
    number: 1,
    name: 'M1: Wealth Planner ED',
    textcolor: '#001B3F',
    backgroundcolor: '#D7E2FF',
  },
  M2: {
    number: 2,
    name: 'M2: Organização',
    textcolor: '#37003A',
    backgroundcolor: '#FFD6F7',
  },
  M3: {
    number: 3,
    name: 'M3: Método Comercial WP',
    textcolor: '#40000A',
    backgroundcolor: '#FFDAD9',
  },
  M4: {
    number: 4,
    name: 'M4: Planejamento e Orçamentos',
    textcolor: '#271900',
    backgroundcolor: '#FFDEAA',
  },
  M5: {
    number: 5,
    name: 'M5: Investimentos',
    textcolor: '#002105',
    backgroundcolor: '#C8FFC1',
  },
  M6: {
    number: 6,
    name: 'M6: Seguros',
    textcolor: '#2D0050',
    backgroundcolor: '#F1DBFF',
  },
  M7: {
    number: 7,
    name: 'M7: Previdência',
    textcolor: '#2D0050',
    backgroundcolor: '#F1DBFF',
  },
  M8:{
    number: 8,
    name: 'M8: Decisões Imobiliárias',
    textcolor: '#271900',
    backgroundcolor: '#FFDEAA',
  },
  M9: {
    number: 9,
    name: 'M9: Finanças Comportamentais',
    textcolor: '#002105',
    backgroundcolor: '#C8FFC1',
  },
  M10: {
    number: 10,
    name: 'M10: Ecossistema ED',
    textcolor: '#001B3F',
    backgroundcolor: '#D7E2FF',
  },
  M11: {
    number: 11,
    name: 'M11: Roteiro de Execução (PC)',
    textcolor: '#37003A',
    backgroundcolor: '#FFD6F7',
  },
  M12: {
    number: 12,
    name: 'M12: Relacionamento',
    textcolor: '#40000A',
    backgroundcolor: '#FFDAD9',
  },
  M13:{
    number: 13,
    name: 'M13: Aquisição e Crescimento',
    textcolor: '#37003A',
    backgroundcolor: '#FFD6F7',
  },
}
const MODULO_NOMES = [
  'Complementar',
  'M1: Wealth Planner ED',
  'M2: Organização',
  'M3: Método Comercial WP',
  'M4: Planejamento e Orçamentos',
  'M5: Investimentos',
  'M6: Seguros',
  'M7: Previdência',
  'M8: Decisões Imobiliárias',
  'M9: Finanças Comportamentais',
  'M10: Ecossistema ED',
  'M11: Roteiro de Execução (PC)',
  'M12: Relacionamento',
  'M13: Aquisição e Crescimento'
];
export {MODULO, MODULO_NOMES}