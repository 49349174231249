import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  error: any;

  constructor(
    public authService: OidcSecurityService,
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.authService.checkAuth().subscribe((isAuthorized: any) => {
        if(isAuthorized==false) {
          this.error = 'Não conseguimos validar seu token. Verifique o horário de seu computador.';
        }
      }, (err) => {
        console.error('error 400');
        this.error = err;
      });
    }, 500);
  }

}
