const PLANEJAMENTOFINANCEIRO = {
  name: 'Planejamento Financeiro',
  textcolor: '#001B3F',
  backgroundcolor: '#D7E2FF',
};
const INVESTIMENTOS = {
  name: 'Investimentos',
  textcolor: '#002105',
  backgroundcolor: '#C8FFC1',
};
const SEGUROS = {
  name: 'Seguros',
  textcolor: '#2D0050',
  backgroundcolor: '#F1DBFF',
};
const CREDITO = { 
  name: 'Crédito',
  textcolor: '#271900',
  backgroundcolor: '#FFDEAA',
};
const VENDAS = {
    name: 'Vendas',
    textcolor: '#40000A',
    backgroundcolor: '#FFDAD9',
  };

export { PLANEJAMENTOFINANCEIRO, INVESTIMENTOS, SEGUROS, CREDITO, VENDAS };
