import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersApplicationsService } from '../users-applications.service';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { ClientResponse } from '../../interfaces/client-response';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationModalComponent } from 'src/app/studies-clientes-screens/modals/configuration-modal/configuration-modal.component';

@Component({
  selector: 'app-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss']
})
export class SelectClientComponent implements OnInit {

  search = new FormControl();
  @ViewChild('searchElement') searchElement: ElementRef;
  usersList: ClientResponse[] = [];
  filteredUsersList: ClientResponse[] = [];
  searchTerm: '';
  @Input()
  application: string;
  clientId: string;
  loading = false;
  pageSize = 5;  
  currentPage = 1;

  constructor(
    public usersApplicationsService: UsersApplicationsService,
    public dialog: MatDialog 
  ) { }

  ngOnInit(): void {
    this.initializeSearch();

    this.usersApplicationsService.userUpdated$.subscribe(() => {
      this.reloadUsers();
    });
  }

  initializeSearch() {
    this.search.valueChanges
      .pipe(
        startWith(''),
        tap(() => this.loading = true),
        switchMap((search) => this.usersApplicationsService.index({ search })),
        map((response: any) => response.results),
        tap(() => this.loading = false),
      )
      .subscribe((usersList: ClientResponse[]) => {
        this.usersList = usersList;
        this.filteredUsersList = this.usersList;
      });

    setTimeout(() => {
      this.searchElement.nativeElement.focus();
    }, 0);
  }

  openLink(id: string) {
    this.clientId = id;
  }

  reloadUsers() {
    this.loading = true;
    this.usersApplicationsService.index({ search: this.search.value })
      .pipe(
        map((response: any) => response.results),
        tap(() => this.loading = false)
      )
      .subscribe((usersList: ClientResponse[]) => {
        this.usersList = usersList;
        this.filteredUsersList = this.usersList;
      });
  }

  openLinkcadastro(subdomain: string, route: string) {
    if (subdomain === 'login' && environment.isQa === true) {
      location.href = ('https://' + subdomain + '.' + environment.baseUrl + route).replace('login.tf', 'login');
      return;
    }
    location.href = (environment.clientRedirect.replace('techfinance', subdomain) + route).replace('.qa.', '.').replace('login.tf', 'login');
  }

  get startIndex(): number {
    return (this.currentPage - 1) * this.pageSize;
  }

  get endIndex(): number {
    return this.currentPage * this.pageSize;
  }

  get totalPages(): number {
    return Math.ceil(this.usersList.length / this.pageSize);
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  searchUsers() {
    this.filteredUsersList = this.usersList.filter(user =>
      user.firstName.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
    this.currentPage = 1;
  }
}
